/*eslint no-unused-vars: "off"*/

/**
 * @see: https://github.com/jaredpalmer/formik/tree/master/examples
 */
import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, CircularProgress, Slider, Radio, RadioGroup, Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';

// Material UI Datepicker START
// @see: https://material-ui-pickers.dev/getting-started/installation
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// pick a date util library
import MomentUtils from '@date-io/moment';
// Material UI Datepicker STOP

import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik';

// s4n 
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Preloader from './../../s4n/Preloader'
import classNames from 'classnames';
import Yup from './../../s4n/Yup';
import FormHelper from './../../s4n/Form'
import { DisplayFormikState } from "./../formikHelper"



const styles = theme => ({
  // paper: {
  //   marginTop: theme.spacing.unit * 8,
  //   display: "flex",
  //   flexDirection: "column",
  //   alignItems: "center",
  //   padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${theme
  //     .spacing.unit * 5}px`
  // },
  success: {
    backgrounColor: "#4caf50",
    borderColor: "#4caf50",
  }
});



const FormStateless = (props) => {
  const { classes, intl } = props;
  const t = intl.formatMessage;

  const [formStatus, setFormStatus] = useState({
    success: null,
    class: null,
    message: null,
  });

  const [state, setState] = useState({
    loading: false,
    error: false,
    formSchema: null,
  });

  const formSchema = {}

  const initialValues = {
    ile_osob_: ``,
    ile_dni_szkolenia_: ``,
    termin_szkolenia: [],
    w_jakim_jezyku_: [],
    gdzie_ma_sie_odbyc_szkolenie_: ``,
    imie_i_nazwisko: ``,
    nazwa_firmy: ``,
    e_mail: ``,
    telefon_kontaktowy: ``,
  }

  const validationSchema = Yup.object().shape({
    ile_osob_: Yup
      .number()
      .typeError(t({ id: "soft4net_form_validation_message_number_default" }))
      // .integer()
      // .positive()
    ,
    ile_dni_szkolenia_: Yup
      .number()
      .typeError(t({ id: "soft4net_form_validation_message_number_default" }))
      .min(2)
      // .integer()
      // .positive()
    ,
    // termin_szkolenia: Yup.date(), // we handle in in Material UI?
    w_jakim_jezyku_: null,
    gdzie_ma_sie_odbyc_szkolenie_: Yup.string(),
    imie_i_nazwisko: Yup.string(),
    nazwa_firmy: Yup.string(),
    e_mail: Yup.string().email().required(),
    telefon_kontaktowy: Yup.string(),
  })

  // Set data for FormHelper START
  // set form ID!!!
  initialValues.webform_id = `heuresis_zapytanie_o_szkolenie_z`;
  // oFormHelper.formEndpoint = '';
  const oFormHelper = new FormHelper(intl);
  
  oFormHelper.t = t;
  oFormHelper.setFormStatus = setFormStatus;
  oFormHelper.state = state;
  oFormHelper.setState = setState;
  // Set data for FormHelper STOP

  /**
   * @see: https://reactjs.org/docs/hooks-effect.html
   * Same as componentDidMount() hook because we pass an empty array [] as second argument,
   * this argument watches for changes in passed arguments
   * In componentDidMount we have access to window object elsewhere not always!!!
   */
  useEffect(() => {
    // Form schema does not contain markup elements!
    oFormHelper.getFormSchema(initialValues.webform_id);
  }, []);



  return (
    <React.Fragment>
      {oFormHelper.state.loading ? // or state.loading this is a reference
        <Preloader /> : 
        <Formik
          initialValues={initialValues}
          onSubmit={oFormHelper.onSubmit}
          validationSchema={validationSchema}
        >

          {(props) => {
            const {
              values,
              errors,
              status,
              touched,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
            } = props;

            // console.log(status)

            const formStatusClass = classNames({
              // "mb-0": true,
              "alert": true,
              "d-none": null === formStatus.class,
              [`alert-${formStatus.class}`]: true,
            });

            return (
              <Form 
                  onSubmit={handleSubmit} 
                  className={initialValues.webform_id}
                >

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_ile_osob_`}
                      label={ oFormHelper.getFieldTitle(`ile_osob_`) }
                      name="ile_osob_"
                      error={errors.ile_osob_ && touched.ile_osob_}
                      className={classes.textField}
                      value={values.ile_osob_}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.ile_osob_ && touched.ile_osob_) && errors.ile_osob_}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_ile_dni_szkolenia_`}
                      label={ oFormHelper.getFieldTitle(`ile_dni_szkolenia_`) }
                      name="ile_dni_szkolenia_"
                      error={errors.ile_dni_szkolenia_ && touched.ile_dni_szkolenia_}
                      className={classes.textField}
                      value={values.ile_dni_szkolenia_}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.ile_dni_szkolenia_ && touched.ile_dni_szkolenia_) && errors.ile_dni_szkolenia_}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    {/* @see: https://material-ui-pickers.dev/demo/datepicker */}
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        clearable
                        // placeholder="10/10/2018"
                        minDate={new Date()}
                        name="termin_szkolenia"
                        disableToolbar
                        variant="inline"
                        format="L"
                        margin="normal"
                        id={`${initialValues.webform_id}_termin_szkolenia`}
                        label={ oFormHelper.getFieldTitle(`termin_szkolenia`) }
                        value={values.termin_szkolenia.length ? values.termin_szkolenia[0] : null} // because this is an array in webform
                        onChange={date => {
                          setFieldValue('termin_szkolenia', [date]) // because this is an array in webform
                        }}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </div>

                <p className="mb-0 pt-5 pb-0">
                    <strong>{ oFormHelper.getFieldTitle(`w_jakim_jezyku_`) }</strong>
                </p>

                {/* @see: https://material-ui.com/components/radio-buttons/ */}
                <RadioGroup 
                    label={ oFormHelper.getFieldTitle(`w_jakim_jezyku_`) }
                    aria-label="w_jakim_jezyku_" 
                    name="w_jakim_jezyku_" 
                    // value={value} 
                    // value={values[elementLabel]} 
                    onChange={(event) => {
                        handleChange(event)
                    }} 
                    // onChange={handleChange} 
                    row
                >
                    {oFormHelper.getFieldItems(`w_jakim_jezyku_`).map((item, index) => {
                        const radio = item.enum[0];

                        return <FormControlLabel
                            value={radio}
                            control={
                                <Radio 
                                    color="primary"
                                    checked={radio === values.w_jakim_jezyku_} // this controls material ui if checked and reset, this reflects current state: if checked, what value has!!!
                                />
                            }
                            label={ item.title }
                            // labelPlacement="end"
                        />
                    })}

                </RadioGroup>

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_gdzie_ma_sie_odbyc_szkolenie_`}
                      label={ oFormHelper.getFieldTitle(`gdzie_ma_sie_odbyc_szkolenie_`) }
                      name="gdzie_ma_sie_odbyc_szkolenie_"
                      error={errors.gdzie_ma_sie_odbyc_szkolenie_ && touched.gdzie_ma_sie_odbyc_szkolenie_}
                      className={classes.textField}
                      value={values.gdzie_ma_sie_odbyc_szkolenie_}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.gdzie_ma_sie_odbyc_szkolenie_ && touched.gdzie_ma_sie_odbyc_szkolenie_) && errors.gdzie_ma_sie_odbyc_szkolenie_}
                      margin="normal"
                    />
                  </div>
                </div>
                
                <p className="mb-0 pt-5 pb-0">
                    <strong>{ t({ id: "soft4net_webform___heuresis_zapytanie_o_szkolenie_z___please_provide_contact_data" }) }</strong>
                </p>

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_imie_i_nazwisko`}
                      label={ oFormHelper.getFieldTitle(`imie_i_nazwisko`) }
                      name="imie_i_nazwisko"
                      error={errors.imie_i_nazwisko && touched.imie_i_nazwisko}
                      className={classes.textField}
                      value={values.imie_i_nazwisko}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.imie_i_nazwisko && touched.imie_i_nazwisko) && errors.imie_i_nazwisko}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_nazwa_firmy`}
                      label={ oFormHelper.getFieldTitle(`nazwa_firmy`) }
                      name="nazwa_firmy"
                      error={errors.nazwa_firmy && touched.nazwa_firmy}
                      className={classes.textField}
                      value={values.nazwa_firmy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.nazwa_firmy && touched.nazwa_firmy) && errors.nazwa_firmy}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField
                      id={`${initialValues.webform_id}_e_mail`}
                      label={ oFormHelper.getFieldTitle(`e_mail`) }
                      name="e_mail"
                      error={errors.e_mail && touched.e_mail}
                      className={classes.textField}
                      value={values.e_mail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.e_mail && touched.e_mail) && errors.e_mail}
                      margin="normal"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <TextField 
                      id={`${initialValues.webform_id}_telefon_kontaktowy`}
                      label={ oFormHelper.getFieldTitle(`telefon_kontaktowy`) }
                      name="telefon_kontaktowy"
                      error={errors.telefon_kontaktowy && touched.telefon_kontaktowy}
                      className={classes.textField}
                      value={values.telefon_kontaktowy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      helperText={(errors.telefon_kontaktowy && touched.telefon_kontaktowy) && errors.telefon_kontaktowy}
                      margin="normal"
                    />
                  </div>
                </div>



                {/* Form status messages */}
                <div className="row form-status">
                  <div className="col">
                    <div dangerouslySetInnerHTML={{ __html: formStatus.message }} className={formStatusClass} role="alert">{/* {formStatus.message} */}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <DialogActions>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        className="outline"
                        onClick={handleReset}
                        disabled={!dirty || isSubmitting}
                      >{ t({ id: "soft4net_form_action_reset" }) }</Button>

                      {isSubmitting ? 
                        <CircularProgress /> : 
                        <Button 
                          type="submit" 
                          variant="contained" 
                          color="primary"
                          className={formStatus && formStatus.success ? 'green' : null}
                          disabled={isSubmitting}>{ t({ id: "soft4net_form_action_submit" }) }</Button>
                      }

                    </DialogActions>
                  </div>
                </div>



                {/* <DisplayFormikState {...props} /> */}



              </Form>
            );
          }}
        </Formik>
      }
      </React.Fragment>
    )
}

export default withStyles(styles)(injectIntl(FormStateless))